import moment from "moment";
import { decryptData, generateKeyAndIV } from "@/services/utils";
import {
  fetchRoles,
  fetchPermissions,
  fetchStaffType,
} from "@/services/adminSourceData";

export default {
  namespaced: true,
  state: {
    staffName: null,
    otpSession: 0,
    sessionName: null,
    onboardData: false,
    permissions: [],
    permissionsList: [],
    roles: [],
    staffTypes: [],
    staffType: null,
    biometricUser: {
      canSkipBiometric: null,
      userId: null,
    },
    isViewMode: true,
    locationName: null,
    newStaff: {
      title: null,
      lastName: null,
      firstName: null,
      middleName: null,
      dateOfBirth: null,
      gender: null,
      nationality: null,
      stateOfOrigin: null,
      lga: null,
      city: null,
    },
  },
  getters: {
    adHocOtpRefresh(state) {
      return Number(moment().format("x")) >= state.otpSession;
    },
    permissionsList(state) {
      return state.permissionsList
        ? state.permissionsList.map((el) => {
            return {
              name: el.action.split("-").join(" ").toUpperCase(),
              id: el._id,
            };
          })
        : [];
    },
    onboardData(state) {
      return state.onboardData ? state.onboardData : null;
    },
    permit: (state) => (act) => {
      return Boolean(
        state.permissions.find((perm) => perm.action === act.toLowerCase())
      );
    },
  },
  mutations: {
    setOtpSession(state, { timestamp, names }) {
      state.otpSession = timestamp;
      state.sessionName = names;
    },
    saveNewStaff(state, dt) {
      state.newStaff = { ...state.newStaff, ...dt };
    },
    clearNewStaff(state) {
      for (const fd in state.newStaff) {
        state.newStaff[fd] = null;
      }
    },
    saveData(state, { key, data }) {
      state[key] = data ?? state[key];
    },
    setViewMode(state, dt) {
      state.isViewMode = dt;
    },
    savePermissions(state, payload) {
      try {
        const key = generateKeyAndIV(process.env.VUE_APP_SECRET_KEY_LOGIN, 32);
        const iv = generateKeyAndIV(process.env.VUE_APP_SECRET_IV_LOGIN, 16);
        console.log({
          method: process.env.VUE_APP_ECNRYPTION_METHOD_LOGIN,
          key: process.env.VUE_APP_SECRET_KEY_LOGIN,
          iv: process.env.VUE_APP_SECRET_IV_LOGIN,
          payload,
        });
        const decode = JSON.parse(decryptData(payload, key, iv));

        console.log({ decode });

        state.permissions = decode.permissions;
      } catch (error) {
        console.log({ error });
      }
    },
    clear(state) {
      // clear state
      const hdr = state.otpSession;
      const hdrname = state.sessionName;
      Object.keys(state).forEach((key) => {
        if (Array.isArray(state[key])) {
          state[key] = [];
        } else {
          state[key] = false;
        }
      });
      state.otpSession = hdr;
      state.sessionName = hdrname;
    },
  },
  actions: {
    async getRoles({ commit, state }) {
      if (!state.roles.length) {
        commit("saveData", {
          key: "roles",
          data: (await fetchRoles()) ?? state.roles,
        });
      }
    },
    async getPermissions({ commit, state }) {
      if (!state.permissionsList.length) {
        commit("saveData", {
          key: "permissionsList",
          data: (await fetchPermissions()) ?? state.permissionsList,
        });
      }
    },
    async getStaffTypes({ commit, state }) {
      if (!state.staffTypes.length) {
        commit("saveData", {
          key: "staffTypes",
          data: (await fetchStaffType()) ?? state.staffTypes,
        });
      }
    },
  },
};
