import crypto from "crypto";

export const useNumberFormatter = (number) => {
  return new Intl.NumberFormat().format(number);
};

// Generate encryption key and IV
export const generateKeyAndIV = (secret, length) => {
  const hash = crypto.createHash("sha256").update(secret).digest("hex");
  return hash.substring(0, length);
};

// Decrypt Data

export const decryptData = (encryptedData, key, iv) => {
  try {
    const buff = Buffer.from(encryptedData, "base64");
    const utfData = buff.toString("utf8");

    const decipher = crypto.createDecipheriv(
      process.env.VUE_APP_ECNRYPTION_METHOD_LOGIN,
      key,
      iv
    );
    let decrypted =
      decipher.update(utfData, "hex", "utf8") + decipher.final("utf8");
    decrypted = decrypted.trim();

    return decrypted;
  } catch (error) {
    console.log({ error });
  }
};
