import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/css/index.css";
import Spinner from "./components/app/Spinner.vue";
import MyTable from "./components/table/MyTable.vue";
import ApiTable from "./components/table/ApiTable.vue";
import Modal from "./components/app/Modal.vue";
import VueSweetalert2 from "vue-sweetalert2";
import VCalendar from "v-calendar";
import vSelect from "vue-select";
import VueApexCharts from "vue-apexcharts";
import prototypeExtensions from "./vue-extensions/prototypes";
import Multiselect from "vue-multiselect";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-select/dist/vue-select.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.config.productionTip = false;

Vue.component("spinner", Spinner);
Vue.component("my-table", MyTable);
Vue.component("api-table", ApiTable);
Vue.component("apexchart", VueApexCharts);
Vue.component("modal", Modal);
Vue.component("v-select", vSelect);

Vue.component("multiselect", Multiselect);

// Use plugin with optional defaults

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
});

Vue.use(VueSweetalert2, {
  confirmButtonColor: "#2FB053",
  cancelButtonColor: "#333231",
});

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: "places", // This is required if you use the Autocomplete plugin
  },
});

Object.assign(Vue.prototype, prototypeExtensions);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
