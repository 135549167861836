export default [
  {
    path: "",
    // eslint-disable-next-line no-unused-vars
    redirect: (_) => {
      const installedFrom = localStorage.getItem("installURL");
      if (installedFrom) {
        return { path: installedFrom };
      }
      return { path: "/login" };
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Login.vue"),
  },
  {
    path: "/signup",
    name: "Signup",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Signup.vue"),
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/PrivacyPolicy.vue"),
  },
  {
    path: "/account-verification",
    name: "Account Verification",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/AccountVerification.vue"),
  },
  {
    path: "/create-password",
    name: "Create Password",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/CreatePassword.vue"),
  },
  {
    path: "/login-otp",
    name: "Verify Otp",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/VerifyOtp.vue"),
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/ForgotPassword.vue"),
  },
  {
    path: "/contact",
    name: "Message Us",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/ContactPage.vue"),
  },
  {
    path: "/bvn-status",
    name: "BVN Status",
    component: () =>
      import(
        /* webpackChunkName: "staff" */ "../views/staff/verification/BvnStatus.vue"
      ),
  },
  {
    path: "/dashboard/:token",
    name: "Dashboard",
    component: () =>
      import(
        /* webpackChunkName: "dashboard"*/ "../views/dashboard/Dashboard.vue"
      ),
  },
];
