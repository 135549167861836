export default [
  {
    path: "/admin/login",
    name: "Admin Login",
    meta: { guest: true },
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/admin/Login.vue"),
  },
  {
    path: "/admin/onboard",
    name: "Admin Onboard",
    meta: { guest: true },
    component: () =>
      import(/* webpackChunk "admin" */ "../views/admin/Onboarding.vue"),
  },
  {
    path: "/admin/forgot-password",
    name: "Admin Forgot Password",
    meta: { guest: true },
    component: () =>
      import(/* webpackChunk "admin" */ "../views/admin/ForgotPassword.vue"),
  },
  {
    path: "/admin/reset-password",
    name: "Admin Reset Password",
    meta: { guest: true },
    component: () =>
      import(/* webpackChunk "admin" */ "../views/admin/ResetPassword.vue"),
  },
  {
    path: "/admin/create-password",
    name: "Admin Create Password",
    meta: { guest: true },
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/admin/CreatePassword.vue"
      ),
  },
  {
    path: "/admin/accreditation/biometric-return",
    name: "Admin Accreditation Return",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/admin/accreditation/Return.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/accreditation/biometric-failed",
    name: "Admin Accreditation Failed",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/admin/accreditation/Failed.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/admin/Wireframe.vue"),
    children: [
      {
        path: "dashboard",
        name: "Admin Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/Dashboard.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "change-password",
        name: "Admin Change Password",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/ChangePassword.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "list-users",
        name: "Admin List Users",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/users/ListUsers.vue"
          ),
        meta: { requiresAuth: true, permit: "get-user" },
      },
      {
        path: "create-user",
        name: "Admin Create User",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/users/CreateUser.vue"
          ),
        meta: { requiresAuth: true, permit: "create-user" },
      },
      {
        path: "profile/:id",
        name: "Admin Profile",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/admin/Profile.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "settings/create-dashboard-link",
        name: "Create Dashboard Link",
        component: () =>
          import(
            /* webpackChunkName: "admin-settings" */ "../views/admin/settings/CreateDashboardLink.vue"
          ),
        meta: { requiresAuth: true, permit: "create-dashboard-link" },
      },
      {
        path: "settings/service-points",
        name: "Service Points",
        component: () =>
          import(
            /* webpackChunkName: "admin-settings" */ "../views/admin/settings/ServicePoints.vue"
          ),
        meta: { requiresAuth: true, permit: "service-points" },
      },
      {
        path: "image-upload",
        name: "Direct Image Upload",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/DirectImageUpload.vue"
          ),
        meta: { requiresAuth: true, permit: "manual-upload" },
      },
      /****************** SIGNATURE **********/
      {
        path: "signature-list",
        name: "Gen 69 Signatures",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/signature/ListSignatures.vue"
          ),
        meta: { requiresAuth: true, permit: "get-signatories" },
      },
      {
        path: "signature-setup",
        name: "Signature",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/signature/SignatureSetup.vue"
          ),
        meta: { requiresAuth: true, permit: "setup-signature" },
      },
      {
        path: "signature-setup/:id",
        name: "View Signature",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/signature/ViewSignature.vue"
          ),
        meta: { requiresAuth: true, permit: "get-signatories" },
      },
      {
        path: "support-views",
        name: "Support View",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/support/View.vue"
          ),
        meta: { requiresAuth: true, permit: "get-contact" },
      },
      {
        path: "reply/:id",
        name: "Support Reply",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/support/Reply.vue"
          ),
        meta: { requiresAuth: true, permit: "get-contact" },
      },
      /****************** RECORDS **********/
      {
        path: "records",
        name: "Admin Records",
        redirect: "records/all-records",
        component: () =>
          import(/* webpackChunkName: "admin" */ "../views/admin/Records.vue"),
        meta: { requiresAuth: true },
        children: [
          {
            path: "all-records",
            name: "All Records",
            component: () =>
              import(
                /* webpackChunkName: "admin" */ "../views/admin/records/AllRecords.vue"
              ),
            meta: { requiresAuth: true },
          },
          {
            path: "approval-needed",
            name: "Approval Needed",
            component: () =>
              import(
                /* webpackChunkName: "admin" */ "../views/admin/records/ApprovalNeeded.vue"
              ),
            meta: { requiresAuth: true, permit: "approve-report" },
          },
          {
            path: "authorization-needed",
            name: "Authorization Needed",
            component: () =>
              import(
                /* webpackChunkName: "admin" */ "../views/admin/records/AuthorizationNeeded.vue"
              ),
            meta: { requiresAuth: true, permit: "authorize-report" },
          },
          {
            path: "location-records",
            name: "Location Records",
            component: () =>
              import(
                /* webpackChunkName: "admin" */ "../views/admin/records/Locations.vue"
              ),
            meta: { requiresAuth: true, permit: "list-locations" },
          },
          {
            path: "operator-records",
            name: "Operator Records",
            component: () =>
              import(
                /* webpackChunkName: "admin" */ "../views/admin/records/Operators.vue"
              ),
            meta: {
              requiresAuth: true,
              permit: "get-user",
            },
          },
        ],
      },

      /****************** RECRUITMENT **********/
      {
        path: "recruit/create-staff",
        name: "Create Staff",
        redirect: "recruit/create-staff/bio-data",
        component: () =>
          import(
            /* webpackChunkName: "admin-staff" */ "../views/admin/newStaff/CreateStaff.vue"
          ),
        meta: { requiresAuth: true, permit: "create-staff" },
        children: [
          {
            path: "bio-data",
            name: "New Staff Bio Data",
            component: () =>
              import(
                /* webpackChunkName: "admin-staff" */ "../views/admin/newStaff/1BioData.vue"
              ),
            meta: { requiresAuth: true, permit: "create-staff" },
          },
          {
            path: "employment-data",
            name: "New Staff Employment Data",
            component: () =>
              import(
                /* webpackChunkName: "admin-staff" */ "../views/admin/newStaff/2EmploymentData.vue"
              ),
            meta: { requiresAuth: true },
          },
          {
            path: "bank-verification",
            name: "New Staff Bank Verification",
            component: () =>
              import(
                /* webpackChunkName: "admin-staff" */ "../views/admin/newStaff/3BankVerification.vue"
              ),
            meta: { requiresAuth: true },
          },
          {
            path: "summary",
            name: "New Staff Summary",
            component: () =>
              import(
                /* webpackChunkName: "admin-staff" */ "../views/admin/newStaff/4Summary.vue"
              ),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: "recruit/new-staff-records",
        name: "New Staff Records",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/records/NewStaffs.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "recruit/all-signature",
        name: "All Signatures",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/records/SignatureRecords.vue"
          ),
        meta: { requiresAuth: true, permit: "sign-gen69" },
      },

      /****************** VERIFICATION **********/
      {
        path: "verification",
        name: "Admin Verification",
        redirect: "verification/bio-data",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/Verification.vue"
          ),
        meta: { requiresAuth: true },
        children: [
          {
            path: "bio-data/:id",
            name: "Admin Bio Data",
            component: () =>
              import(
                /* webpackChunkName: "admin" */ "../views/admin/verification/1BioData.vue"
              ),
            meta: { requiresAuth: true },
          },
          {
            path: "next-of-kin/:id",
            name: "Admin NextOfKin",
            component: () =>
              import(
                /* webpackChunkName: "admin" */ "../views/admin/verification/2NextOfKin.vue"
              ),
            meta: { requiresAuth: true },
          },
          {
            path: "education/:id",
            name: "Admin Education",
            component: () =>
              import(
                /* webpackChunkName: "admin" */ "../views/admin/verification/3Education.vue"
              ),
            meta: { requiresAuth: true },
          },
          {
            path: "employment-data/:id",
            name: "Admin Employment Data",
            component: () =>
              import(
                /* webpackChunkName: "admin" */ "../views/admin/verification/4EmploymentData.vue"
              ),
            meta: { requiresAuth: true },
          },
          {
            path: "bank-information/:id",
            name: "Admin Bank Information",
            component: () =>
              import(
                /* webpackChunkName: "admin" */ "../views/admin/verification/5BankInformation.vue"
              ),
            meta: { requiresAuth: true },
          },
          {
            path: "bvn-verify/:id",
            name: "Admin BVN Verify",
            component: () =>
              import(
                /* webpackChunkName: "admin" */ "../views/admin/verification/6BvnVerify.vue"
              ),
            meta: { requiresAuth: true },
          },
          {
            path: "document-upload/:id",
            name: "Admin Document Upload",
            component: () =>
              import(
                /* webpackChunkName: "admin" */ "../views/admin/verification/7DocumentUploads.vue"
              ),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: "accreditation/biometric-start/:id",
        name: "Admin Accreditation Start",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/accreditation/Start.vue"
          ),
      },
      /****************** REPORTS **********/
      {
        path: "report",
        name: "Report",
        redirect: "report/nominal-roll",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/reports/ReportType.vue"
          ),
        meta: { requiresAuth: true, permit: "report-viewer" },
        children: [
          {
            path: "nominal-roll",
            name: "Nominal Roll Report",
            component: () =>
              import(
                /* webpackChunkName: "admin" */ "../components/admin/reports/NominalRoll.vue"
              ),
            meta: { requiresAuth: true, permit: "report-viewer" },
          },
          {
            path: "pay-roll",
            name: "Pay Roll Report",
            component: () =>
              import(
                /* webpackChunkName: "admin" */ "../components/admin/reports/Payroll.vue"
              ),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: "report/picture",
        name: "Picture Report",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/reports/PictureReport.vue"
          ),
        meta: { requiresAuth: true, permit: "report-viewer" },
      },

      /* ****************** CLOCK-INS ******************* */
      {
        path: "clock-in/setup",
        name: "Admin Setup Clockin",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/clockin/Setup.vue"
          ),
        meta: {
          requiresAuth: true,
          permit: "set-clock-in",
        },
      },

      {
        path: "clock-in/add-location",
        name: "Admin Add Location",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/clockin/AddLocation.vue"
          ),
        meta: { requiresAuth: true, permit: "add-location" },
      },

      {
        path: "location/:lid/staffs/:sid",
        name: "Staff Clockin History",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/clockin/StaffHistory.vue"
          ),
        meta: { requiresAuth: true, permit: "list-clockin-history" },
      },
      {
        path: "location/staffs/:sid",
        name: "List Staff Clockin History",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/clockin/StaffClockinHistory.vue"
          ),
        meta: { requiresAuth: true, permit: "list-clockin-history" },
      },

      {
        path: "location",
        name: "Admin Location",
        redirect: "location/details",
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../views/admin/clockin/Location.vue"
          ),
        children: [
          {
            path: ":id/details",
            name: "Location Details",
            component: () =>
              import(
                /* webpackChunkName: "admin" */ "../views/admin/clockin/location/Details.vue"
              ),
            meta: { requiresAuth: true, permit: "get-location-details" },
          },
          {
            path: ":id/staffs",
            name: "Location Staffs",
            component: () =>
              import(
                /* webpackChunkName: "admin" */ "../views/admin/clockin/location/Staffs.vue"
              ),
            meta: { requiresAuth: true, permit: "get-location-staffs" },
          },
          {
            path: ":id/assign-staffs",
            name: "Assign Staffs",
            component: () =>
              import(
                /* webpackChunkName: "admin" */ "../views/admin/clockin/location/AssignStaff.vue"
              ),
            meta: { requiresAuth: true, permit: "assign-staff-location" },
          },
        ],
      },

      /* ************* SYSTEM ROUTES ***************** */
      {
        path: "system/on-board-staff",
        name: "Admin Staff Upload",
        component: () =>
          import(
            /* webpackChunkName: "system-admin" */ "../views/admin/system/StaffUpload.vue"
          ),
      },
      {
        path: "system/mda-list",
        name: "Admin MDA List",
        component: () =>
          import(
            /* webpackChunkName: "system-admin" */ "../views/admin/system/ListMdas.vue"
          ),
      },
      {
        path: "system/department-list",
        name: "Admin Department List",
        component: () =>
          import(
            /* webpackChunkName: "system-admin" */ "../views/admin/system/ListDepartments.vue"
          ),
      },
      {
        path: "system/bank-list",
        name: "Admin Bank List",
        component: () =>
          import(
            /* webpackChunkName: "system-admin" */ "../views/admin/system/ListBanks.vue"
          ),
      },
    ],
  },
];
