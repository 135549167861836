import {
  fetchTitle,
  fetchCountries,
  fetchStates,
  fetchStaffProfile,
  fetchStaffNxOfKin,
  fetchRelationships,
  fetchStaffEducation,
  fetchCadres,
  fetchSalaryGradeLevels,
  fetchGradeSteps,
  fetchMdas,
  fetchDepartments,
  fetchDesignations,
  fetchPfas,
  fetchEmploymentInfo,
  fetchSalaryAccount,
  fetchBanks,
  fetchQualifications,
  fetchDocSetup,
  fetchUnions,
  fetchPensionInfo,
  fetchAcademicCategories,
  fetchProfessionalBodies,
  fetchProfessionalSkills,
} from "@/services/sourceData";

import {
  pensionierVerificationPages,
  staffVerificationPages,
} from "@/services/staffProfile";

export default {
  namespaced: true,
  state: {
    step: 0,
    vStep: 3,
    bioStep: 1,
    titles: [],
    countries: [],
    states: [],
    profile: {},
    nok: {},
    unions: [],
    empInfo: {},
    relationships: [],
    educations: [],
    professionalBodiesInfo: [],
    cadres: [],
    salaryGradeLevels: [],
    gradeSteps: [],
    ninPayload: {},
    mdas: [],
    departments: [],
    designations: [],
    pfas: [],
    salaryAccount: [],
    banks: [],
    qualifications: [],
    docSetup: [],
    bvnNinStep: 1,
    pensionInfo: [],
    academicCategories: [],
    professionalBodies: [],
    professionalSkills: [],
  },
  getters: {
    userpic(state) {
      return state.user ? state.user.profilePhoto : null;
    },
    ninPayload(state) {
      return state.ninPayload ? state.ninPayload : null;
    },
    profile(state) {
      return state.profile ? state.profile : {};
    },
    step(state) {
      return state.vStep;
    },
    stepStatus: (state) => (step) => {
      return state.profile?.verificationStep[step] ?? 0;
    },
    verifyStep(state) {
      return state.profile
        ? state.profile.verificationStep.reduce((sm, a) => sm + a, 0)
        : 0;
    },
    nextStage(state) {
      const nx = state.profile ? state.profile.verificationStep.indexOf(0) : -1;

      const pages =
        state?.profile?.staffType &&
        state?.profile?.staffType.includes("pension")
          ? pensionierVerificationPages
          : staffVerificationPages;

      return nx !== -1 ? pages[nx].link : pages[pages.length - 1].link;
    },
  },
  mutations: {
    clear(state) {
      // clear state
      Object.keys(state).forEach((key) => {
        if (Array.isArray(state[key])) {
          state[key] = [];
        } else {
          state[key] = false;
        }
      });
    },
    updateVerifyStep(state, st) {
      state.profile.verificationStep[st - 1] = 1;
    },
    updateBStep(state, step) {
      state.bioStep = step;
    },
    saveData(state, { key, data }) {
      state[key] = data ?? state[key];
    },
    updateData(state, { key, data }) {
      state[key] = { ...state[key], ...data };
    },
    updateNINData(state, data) {
      state.ninPayload = {
        ...data,
      };
    },
  },
  actions: {
    async getTitles({ commit, state }) {
      if (!state.titles.length) {
        commit("saveData", { key: "titles", data: await fetchTitle() });
      }
    },
    async getCountries({ commit, state }) {
      if (!state.countries.length) {
        commit("saveData", { key: "countries", data: await fetchCountries() });
      }
    },
    async getStates({ commit, state }) {
      if (!state.states.length) {
        commit("saveData", { key: "states", data: await fetchStates() });
      }
    },
    async getProfile({ commit, state }) {
      commit("saveData", {
        key: "profile",
        data: (await fetchStaffProfile()) ?? state.profile,
      });
    },
    async getNextOfKin({ commit, state }) {
      commit("saveData", {
        key: "nok",
        data: (await fetchStaffNxOfKin()) ?? state.nok,
      });
    },
    async getRelationships({ commit, state }) {
      if (!state.relationships.length) {
        commit("saveData", {
          key: "relationships",
          data: await fetchRelationships(),
        });
      }
    },

    async getUnions({ commit, state }) {
      if (!state.unions.length) {
        commit("saveData", {
          key: "unions",
          data: await fetchUnions(),
        });
      }
    },

    async getEducation({ commit, state }) {
      const { educations, professionalBodies } = await fetchStaffEducation();
      commit("saveData", {
        key: "educations",
        data: educations ?? state.educations,
      });
      commit("saveData", {
        key: "professionalBodiesInfo",
        data: professionalBodies ?? state.professionalBodies,
      });
    },

    async getCadres({ commit, state }) {
      commit("saveData", {
        key: "cadres",
        data: (await fetchCadres()) ?? state.cadres,
      });
    },

    async getSalaryGradeLevels({ commit, state }) {
      if (!state.salaryGradeLevels.length) {
        commit("saveData", {
          key: "salaryGradeLevels",
          data: (await fetchSalaryGradeLevels()) ?? state.salaryGradeLevels,
        });
      }
    },

    async getGradeSteps({ commit, state }) {
      if (!state.gradeSteps.length) {
        commit("saveData", {
          key: "gradeSteps",
          data: (await fetchGradeSteps()) ?? state.gradeSteps,
        });
      }
    },
    async getMdas({ commit, state }) {
      if (!state.mdas.length) {
        commit("saveData", {
          key: "mdas",
          data: (await fetchMdas()) ?? state.mdas,
        });
      }
    },
    async getDepartments({ commit, state }) {
      if (!state.departments.length) {
        commit("saveData", {
          key: "departments",
          data: (await fetchDepartments()) ?? state.departments,
        });
      }
    },
    async getDesignations({ commit, state }) {
      if (!state.designations.length) {
        commit("saveData", {
          key: "designations",
          data: (await fetchDesignations()) ?? state.designations,
        });
      }
    },
    async getPfas({ commit, state }) {
      if (!state.pfas.length) {
        commit("saveData", {
          key: "pfas",
          data: (await fetchPfas()) ?? state.pfas,
        });
      }
    },
    async getEmploymentInfo({ commit, state }) {
      commit("saveData", {
        key: "empInfo",
        data: (await fetchEmploymentInfo()) ?? state.empInfo,
      });
    },
    async getPensionInfo({ commit, state }) {
      commit("saveData", {
        key: "pensionInfo",
        data: (await fetchPensionInfo()) ?? state.pensionInfo,
      });
    },
    async getSalaryAccount({ commit, state }) {
      commit("saveData", {
        key: "salaryAccount",
        data: (await fetchSalaryAccount()) ?? state.salaryAccount,
      });
    },
    async getBanks({ commit, state }) {
      if (!state.banks.length) {
        commit("saveData", {
          key: "banks",
          data: (await fetchBanks()) ?? state.banks,
        });
      }
    },
    async getQualifications({ commit, state }) {
      if (!state.qualifications.length) {
        commit("saveData", {
          key: "qualifications",
          data: (await fetchQualifications()) ?? state.qualifications,
        });
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getDocSetup({ commit, state, _, rootGetters }) {
      if (!state.docSetup.length) {
        commit("saveData", {
          key: "docSetup",
          data:
            (await fetchDocSetup(rootGetters["general/staffType"])) ??
            state.docSetup,
        });
      }
    },
    async getAcademicCategories({ commit, state }) {
      if (!state.academicCategories.length) {
        commit("saveData", {
          key: "academicCategories",
          data: (await fetchAcademicCategories()) ?? state.academicCategories,
        });
      }
    },
    // async getAcademicDegrees({ commit, state }) {
    //   if (!state.academicDegrees.length) {
    //     commit("saveData", {
    //       key: "academicDegrees",
    //       data: (await fetchAcademicDegrees()) ?? state.academicDegrees,
    //     });
    //   }
    // },
    // async getAcademicQualifications({ commit, state }) {
    //   if (!state.academicQualifications.length) {
    //     commit("saveData", {
    //       key: "academicQualifications",
    //       data:
    //         (await fetchAcademicQualification()) ??
    //         state.academicQualifications,
    //     });
    //   }
    // },
    async getProfessionalBodies({ commit, state }) {
      if (!state.professionalBodies.length) {
        commit("saveData", {
          key: "professionalBodies",
          data: (await fetchProfessionalBodies()) ?? state.professionalBodies,
        });
      }
    },
    async getProfessionalSkills({ commit, state }) {
      if (!state.professionalSkills.length) {
        commit("saveData", {
          key: "professionalSkills",
          data: (await fetchProfessionalSkills()) ?? state.professionalSkills,
        });
      }
    },
  },
};
