import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import general from "./general";
import staff from "./staff";
import admin from "./admin";

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
  key: process.env.VUE_APP_appDB,
  storage: window.localStorage,
});

export default new Vuex.Store({
  modules: {
    admin,
    staff,
    general,
  },
  plugins: [vuexLocal.plugin],
});
