export default {
  namespaced: true,
  state: {
    overlay: false,
    loggedIn: false,
    sideOpen: false,
    user: null,
    ninStep: 1,
  },
  getters: {
    userId(state) {
      if (state.user && typeof state.user === "string") return state.user;
      return state.user ? state.user.id : null;
    },
    staffType(state) {
      return state.user ? state.user.staffType : "civils";
    },
    userType(state) {
      return state.user ? state.user.roleName : "N/A";
    },
    user(state) {
      return state.user ? state.user : null;
    },
    userpic(state) {
      return state.user ? state.user.profilePhoto : null;
    },
    username(state) {
      return state.user
        ? `${state.user.firstName} ${state.user.lastName}`
        : "N/A";
    },
    fullName(state) {
      return state.user
        ? `${state.user.lastName} ${state.user.middleName} ${state.user.firstName}`
        : "N/A";
    },
  },
  mutations: {
    toggleNavBar(state, status = "q") {
      if (status !== "q") {
        state.sideOpen = status;
      } else {
        state.sideOpen = !state.sideOpen;
      }
    },
    toggleLoad(state) {
      state.overlay = !state.overlay;
    },
    hideSurvey(state) {
      state.user.hideSurvey = true;
    },
    saveUser(state, { user, token }) {
      if (token) localStorage.setItem(process.env.VUE_APP_tokenName, token);

      state.user = user;
    },
    login(state) {
      state.loggedIn = true;
    },
    clear(state) {
      state.loggedIn = false;
      localStorage.removeItem(process.env.VUE_APP_tokenName);

      // clear state
      Object.keys(state).forEach((key) => {
        if (Array.isArray(state[key])) {
          state[key] = [];
        } else {
          state[key] = false;
        }
      });

      const installedFrom = localStorage.getItem("installURL") || "/login";
      localStorage.removeItem(process.env.VUE_APP_appDB);
      localStorage.clear();
      localStorage.setItem("installURL", installedFrom);
    },
  },
  actions: {
    logout({ commit }) {
      commit("staff/clear", null, { root: true });
      commit("admin/clear", null, { root: true });
      commit("clear");
    },
  },
};
