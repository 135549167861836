import sa from "./axiosInstance";

export const fetchRoles = async () => {
  const res = await sa.get("/roles");
  return res ? res.data.data.data : [];
};

export const fetchPermissions = async () => {
  const res = await sa.get("/permissions?limit=56");
  return res ? res.data.data.data : [];
};

export const fetchStaffType = async () => {
  const res = await sa.get("/staff-type");
  return res ? res.data : [];
};
