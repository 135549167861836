export default [
  {
    path: "/staff",
    redirect: "/staff/dashboard",
    component: () =>
      import(/* webpackChunkName: "staff" */ "../views/staff/Scaffold.vue"),
    children: [
      {
        path: "dashboard",
        name: "Staff Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "staff" */ "../views/staff/Dashboard.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "change-password",
        name: "Staff Change Password",
        component: () =>
          import(
            /* webpackChunkName: "staff" */ "../views/staff/ChangePassword.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "verification",
        name: "Staff Verification",
        redirect: "verification/bio-data",
        component: () =>
          import(
            /* webpackChunkName: "verification" */ "../views/staff/Verification.vue"
          ),
        children: [
          {
            path: "bio-data",
            name: "Staff Bio Data",
            redirect: "bio-data/personal",
            component: () =>
              import(
                /* webpackChunkName: "verification" */ "../views/staff/verification/BioData.vue"
              ),
            meta: { requiresAuth: true },
            children: [
              {
                path: "personal",
                name: "Staff Section A",
                component: () =>
                  import(
                    /* webpackChunkName: "verification" */ "../views/staff/verification/biodata/SectionA.vue"
                  ),
                meta: { requiresAuth: true },
              },
              {
                path: "next-of-kin",
                name: "Staff Section B",
                component: () =>
                  import(
                    /* webpackChunkName: "verification" */ "../views/staff/verification/biodata/SectionB.vue"
                  ),
                meta: { requiresAuth: true },
              },
              {
                path: "education",
                name: "Staff Section C",
                component: () =>
                  import(
                    /* webpackChunkName: "verification" */ "../views/staff/verification/biodata/SectionC.vue"
                  ),
                meta: { requiresAuth: true },
              },
              {
                path: "employment-data",
                name: "Staff Section D",
                component: () =>
                  import(
                    /* webpackChunkName: "verification" */ "../views/staff/verification/biodata/SectionD.vue"
                  ),
                meta: { requiresAuth: true },
              },
              {
                path: "bank-info",
                name: "Staff Section E",
                component: () =>
                  import(
                    /* webpackChunkName: "verification" */ "../views/staff/verification/biodata/SectionE.vue"
                  ),
                meta: { requiresAuth: true },
              },
            ],
          },
          {
            path: "document-upload",
            name: "Staff Document Upload",
            component: () =>
              import(
                /* webpackChunkName: "verification" */ "../views/staff/verification/DocumentUpload.vue"
              ),
            meta: { requiresAuth: true },
          },
          {
            path: "nin-verification",
            name: "Staff NIN Verification",
            redirect: "nin-verification/step-1",
            component: () =>
              import(
                /* webpackChunkName: "verification" */ "../views/staff/verification/NinVerification.vue"
              ),
            meta: { requiresAuth: true },
            children: [
              {
                path: "step-1",
                name: "NIN Step-1",
                component: () =>
                  import(
                    /* webpackChunkName: "verification" */ "../views/staff/verification/nin/Step1.vue"
                  ),
                meta: { requiresAuth: true },
              },
              {
                path: "step-2",
                name: "NIN Step-2",
                component: () =>
                  import(
                    /* webpackChunkName: "verification" */ "../views/staff/verification/nin/Step2.vue"
                  ),
                meta: { requiresAuth: true },
              },
              // {
              //   path: "step-3",
              //   name: "NIN Step-3",
              //   component: () =>
              //     import(
              //       /* webpackChunkName: "verification" */ "../views/staff/verification/nin/Step3.vue"
              //     ),
              //   meta: { requiresAuth: true },
              // },

              // {
              //   path: "step-4",
              //   name: "NIN Step-4",
              //   component: () =>
              //     import(
              //       /* webpackChunkName: "verification" */ "../views/staff/verification/nin/Step4.vue"
              //     ),
              //   meta: { requiresAuth: true },
              // },
              // {
              //   path: "step-5",
              //   name: "NIN Step-5",
              //   component: () =>
              //     import(
              //       /* webpackChunkName: "verification" */ "../views/staff/verification/nin/Step5.vue"
              //     ),
              //   meta: { requiresAuth: true },
              // },
              // {
              //   path: "response",
              //   name: "NIN Warning",
              //   component: () =>
              //     import(
              //       /* webpackChunkName: "verification" */ "../views/staff/verification/nin/NinWarning.vue"
              //     ),
              //   meta: { requiresAuth: true },
              // },
            ],
          },
          {
            path: "bvn-verification",
            name: "Staff Bvn Verification",
            component: () =>
              import(
                /* webpackChunkName: "verification" */ "../views/staff/verification/BvnVerification.vue"
              ),
            meta: { requiresAuth: true },
          },

          {
            path: "verification-summary",
            name: "Staff Verification Summary",
            component: () =>
              import(
                /* webpackChunkName: "verification" */ "../views/staff/verification/ReportSummary.vue"
              ),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: "pension",
        name: "Pensioner",
        redirect: "pension/bio-data",
        component: () =>
          import(
            /* webpackChunkName: "pension" */ "../views/staff/pension/Pension.vue"
          ),
        children: [
          {
            path: "bio-data",
            name: "Pension Bio Data",
            redirect: "bio-data/personal",
            component: () =>
              import(
                /* webpackChunkName: "pension" */ "../views/staff/pension/BioData.vue"
              ),
            meta: { requiresAuth: true },
            children: [
              {
                path: "personal",
                name: "Pension Section A",
                component: () =>
                  import(
                    /* webpackChunkName: "pension" */ "../views/staff/pension/biodata/SectionA.vue"
                  ),
                meta: { requiresAuth: true },
              },
              {
                path: "next-of-kin",
                name: "Pension Section B",
                component: () =>
                  import(
                    /* webpackChunkName: "pension" */ "../views/staff/pension/biodata/SectionB.vue"
                  ),
                meta: { requiresAuth: true },
              },

              {
                path: "work-info",
                name: "Pension Section C",
                component: () =>
                  import(
                    /* webpackChunkName: "pension" */ "../views/staff/pension/biodata/SectionC.vue"
                  ),
                meta: { requiresAuth: true },
              },
              {
                path: "bank-info",
                name: "Pension Section D",
                component: () =>
                  import(
                    /* webpackChunkName: "pension" */ "../views/staff/pension/biodata/SectionD.vue"
                  ),
                meta: { requiresAuth: true },
              },
            ],
          },
          {
            path: "document-upload",
            name: "Pension Document Upload",
            component: () =>
              import(
                /* webpackChunkName: "pension" */ "../views/staff/pension/DocumentUpload.vue"
              ),
            meta: { requiresAuth: true },
          },
          {
            path: "bvn-verification",
            name: "Pension Bvn Verification",
            component: () =>
              import(
                /* webpackChunkName: "pension" */ "../views/staff/pension/BvnVerification.vue"
              ),
            meta: { requiresAuth: true },
          },

          {
            path: "verification-summary",
            name: "Pension Verification Summary",
            component: () =>
              import(
                /* webpackChunkName: "pension" */ "../views/staff/pension/ReportSummary.vue"
              ),
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: "survey-professional",
        name: "Staff Survey",
        component: () =>
          import(
            /* webpackChunkName: "staff" */ "../views/staff/survey/Professional.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "clock-in/history",
        name: "Staff Clock-in History",
        component: () =>
          import(
            /* webpackChunkName: "staff" */ "../views/staff/clockin/History.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "clock-in/stats",
        name: "Staff Clock-in Stats",
        component: () =>
          import(
            /* webpackChunkName: "staff" */ "../views/staff/clockin/Stats.vue"
          ),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/staff-printout/:verificationCode",
    name: "Staff Summary Report",
    component: () =>
      import(
        /* webpackChunkName: "staff" */ "../views/staff/verification/Printout.vue"
      ),
    meta: { requiresAuth: false },
  },
  {
    path: "/payslip/:payslipId",
    name: "Staff Payslip Print",
    component: () =>
      import(/* webpackChunkName: "staff" */ "../views/staff/PayslipScan.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/certificate/:verificationCode",
    name: "Staff Certifcate",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/staff/verification/Certificate.vue"
      ),
    meta: { requiresAuth: false },
  },
  {
    path: "/gen69/:genId",
    name: "Staff GEN69",
    component: () =>
      import(/* webpackChunkName: "staff" */ "../views/staff/Gen69Print.vue"),
    meta: { requiresAuth: false },
  },
];
