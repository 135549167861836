export const StaffProfile = {
  profilePhoto: null,
  loginToken: null,
  verifiedBy: null,
  verificationDate: null,
  approvalDate: null,
  approvedBy: null,
  acceptedDate: null,
  acceptedBy: null,
  rejectedDate: null,
  rejectedBy: null,
  rejectReason: null,
  authorizedBy: null,
  authorizedDate: null,
  _id: null,
  verificationCode: null,
  employmentNumber: null,
  firstName: null,
  lastName: null,
  middleName: null,
  title: null,
  dateOfBirth: null,
  maritalStatus: null,
  gender: null,
  townOfResidence: null,
  cityOfResidence: null,
  stateOfResidence: null,
  phoneNumber: null,
  dateOfAppointment: null,
  salaryGradeLevel: null,
  currentStep: null,
  departmentName: null,
  mdaName: null,
  salaryAccountNumber: null,
  bankName: null,
  bvn: null,
  salaryStructure: null,
  cadre: null,
  branchName: null,
  accountType: null,
  email: null,
  approvalStatus: null,
  authorized: false,
  emailVerificationCode: null,
  emailVerified: true,
  isActive: true,
  phoneVerificationCode: null,
  phoneVerified: true,
  verificationCompleted: false,
  verificationStep: [0, 0, 0, 0, 0, 0, 0, 0],
  verificationStatus: null,
  verificationUsed: true,
  verifiedDocuments: false,
  activationCode: null,
  lastLogin: null,
  id: null,
};

export const StaffNxOKin = {
  firstName: null,
  lastName: null,
  middleName: null,
  relationship: null,
  gender: null,
  phone: null,
  address: null,
};

export const EmploymentInfo = {
  acceptedBy: null,
  acceptedDate: null,
  approvalDate: null,
  approvalStatus: null,
  approvedBy: null,
  cadre: null,
  confirmationDate: null,
  createdAt: null,
  dateOfAppointment: null,
  dateOfPresentConfirmation: null,
  dateOfRetirement: null,
  departmentCode: null,
  designation: null,
  employeeId: null,
  employmentStatus: null,
  lastPromotion: null,
  mdaCode: null,
  nationalIdNumber: null,
  pensionNumber: null,
  pfa: null,
  presentDuty: null,
  rejectReason: null,
  rejectedBy: null,
  rejectedDate: null,
  salaryGradeLevel: null,
  secretFile: null,
  step: null,
  unionNumber: null,
  updatedAt: null,
  verificationCode: null,
};

export const PensionInfo = {
  dateOfLastPromotion: null,
  departmentId: null,
  placeOFLastEmployment: null,
  employeeId: null,
  verificationCode: null,
  unionNumber: null,
  unionName: null,
  employmentStatus: null,
  dateOfAppointment: null,
  dateOfRetirement: null,
  presentDuty: null,
  pensionNumber: null,
  pfa: null,
  cadre: null,
  salaryGradeLevel: null,
  step: null,
  secretFile: null,
  lastPromotion: null,
  mdaCode: null,
  mdaName: null,
  mdaId: null,
  departmentCode: null,
  departmentName: null,
  retirementPolicyId: null,
  nationalIdNumber: null,
  designation: null,
  approvalStatus: null,
  approvalDate: null,
  approvedBy: null,
  authorizedDate: null,
  authorizedBy: null,
  rejectedDate: null,
  rejectedBy: null,
  rejectReason: null,
  ageAtRetirement: null,
  placeOfFirstAssignment: null,
  dateOfFirstEmployment: null,
};

export const staffVerificationPages = [
  { step: 1, link: "Staff Section A" },
  { step: 2, link: "Staff Section B" },
  { step: 3, link: "Staff Section C" },
  { step: 4, link: "Staff Section D" },
  { step: 5, link: "Staff Section E" },
  { step: 6, link: "Staff Document Upload" },
  { step: 7, link: "Staff NIN Verification" },
  { step: 8, link: "Staff Bvn Verification" },
  { step: 9, link: "Staff Verification Summary" },
];

export const pensionierVerificationPages = [
  { step: 1, link: "Pension Section A" },
  { step: 2, link: "Pension Section B" },
  { step: 3, link: "Pension Section Education" },
  { step: 4, link: "Pension Section C" },
  { step: 5, link: "Pension Section D" },
  { step: 6, link: "Pension Document Upload" },
  { step: 7, link: "Staff NIN Verification" },
  { step: 8, link: "Pension Bvn Verification" },
  { step: 9, link: "Pension Verification Summary" },
];

export const analytics = [
  {
    name: "Staff Count",
    data: [
      {
        m1: 4000,
        m2: 500,
        status: true,
        difference: 3500,
        isCurrency: false,
      },
      {
        m1: 2,
        m2: 4,
        status: true,
        difference: 2,
        isCurrency: false,
      },
    ],
    total: {
      m1: 2,
      m2: 4,
      status: true,
      difference: 2,
      isCurrency: false,
    },
  },

  {
    name: "Total Gross",
    data: [
      {
        m1: 10000,
        m2: 20000,
        status: true,
        difference: 2,
        isCurrency: true,
      },
      {
        m1: 29000,
        m2: 10000,
        status: false,
        difference: 2,
        isCurrency: true,
      },
    ],
    total: {
      m1: 2000,
      m2: 4000,
      status: true,
      difference: 2,
      isCurrency: true,
    },
  },
  {
    name: "Total Net",
    data: [
      {
        m1: 200000,
        m2: 400000,
        status: true,
        difference: 200000,
        isCurrency: true,
      },
      {
        m1: 10000,
        m2: 800,
        status: false,
        difference: 9200,
        isCurrency: true,
      },
    ],
    total: {
      m1: 90000,
      m2: 40000,
      status: false,
      difference: 50000,
      isCurrency: true,
    },
  },
  {
    name: "New Pensioners",
    data: [
      {
        m1: 10000,
        m2: 5000,
        status: false,
        difference: 5000,
        isCurrency: false,
      },
      {
        m1: 2000,
        m2: 4000,
        status: true,
        difference: 2000,
        isCurrency: false,
      },
    ],
    total: {
      m1: 2,
      m2: 4,
      status: true,
      difference: 2,
      isCurrency: false,
    },
  },
  {
    name: "Deceased Pensioners",
    data: [
      {
        m1: 2000,
        m2: 4000,
        status: true,
        difference: 2000,
        isCurrency: false,
      },
      {
        m1: 2000,
        m2: 4000,
        status: true,
        difference: 2000,
        isCurrency: false,
      },
    ],
    total: {
      m1: 2000,
      m2: 4000,
      status: true,
      difference: 2000,
      isCurrency: false,
    },
  },
];

export const staffAnalytics = [
  {
    name: "Staff Count",
    data: [
      {
        m1: 4000,
        m2: 500,
        status: true,
        difference: 3500,
        isCurrency: false,
      },
      {
        m1: 2000,
        m2: 4000,
        status: true,
        difference: 2000,
        isCurrency: false,
      },
      {
        m1: 40000,
        m2: 50000,
        status: true,
        difference: 10000,
        isCurrency: false,
      },
      {
        m1: 25000,
        m2: 40000,
        status: true,
        difference: 10000,
        isCurrency: false,
      },
      {
        m1: 40000,
        m2: 20000,
        status: false,
        difference: 20000,
        isCurrency: false,
      },
      {
        m1: 70000,
        m2: 45000,
        status: false,
        difference: 45000,
        isCurrency: false,
      },
    ],
    total: {
      m1: 220000,
      m2: 440000,
      status: true,
      difference: 220000,
      isCurrency: false,
    },
  },

  {
    name: "Total Gross",
    data: [
      {
        m1: 10000,
        m2: 20000,
        status: true,
        difference: 2,
        isCurrency: true,
      },
      {
        m1: 29000,
        m2: 10000,
        status: false,
        difference: 2,
        isCurrency: true,
      },
      {
        m1: 10000,
        m2: 20000,
        status: true,
        difference: 2,
        isCurrency: true,
      },
      {
        m1: 29000,
        m2: 10000,
        status: false,
        difference: 2,
        isCurrency: true,
      },
      {
        m1: 10000,
        m2: 20000,
        status: true,
        difference: 2,
        isCurrency: true,
      },
      {
        m1: 29000,
        m2: 10000,
        status: false,
        difference: 2,
        isCurrency: true,
      },
    ],
    total: {
      m1: 2000,
      m2: 4000,
      status: true,
      difference: 2,
      isCurrency: true,
    },
  },
  {
    name: "Total Net",
    data: [
      {
        m1: 200000,
        m2: 400000,
        status: true,
        difference: 200000,
        isCurrency: true,
      },
      {
        m1: 10000,
        m2: 800,
        status: false,
        difference: 9200,
        isCurrency: true,
      },
      {
        m1: 200000,
        m2: 400000,
        status: true,
        difference: 200000,
        isCurrency: true,
      },
      {
        m1: 10000,
        m2: 800,
        status: false,
        difference: 9200,
        isCurrency: true,
      },
      {
        m1: 200000,
        m2: 400000,
        status: true,
        difference: 200000,
        isCurrency: true,
      },
      {
        m1: 10000,
        m2: 800,
        status: false,
        difference: 9200,
        isCurrency: true,
      },
    ],
    total: {
      m1: 90000,
      m2: 40000,
      status: false,
      difference: 50000,
      isCurrency: true,
    },
  },
  {
    name: "New Staff",
    data: [
      {
        m1: 10000,
        m2: 5000,
        status: false,
        difference: 5000,
        isCurrency: false,
      },
      {
        m1: 2000,
        m2: 4000,
        status: true,
        difference: 2000,
        isCurrency: false,
      },
      {
        m1: 10000,
        m2: 5000,
        status: false,
        difference: 5000,
        isCurrency: false,
      },
      {
        m1: 2000,
        m2: 4000,
        status: true,
        difference: 2000,
        isCurrency: false,
      },
      {
        m1: 10000,
        m2: 5000,
        status: false,
        difference: 5000,
        isCurrency: false,
      },
      {
        m1: 2000,
        m2: 4000,
        status: true,
        difference: 2000,
        isCurrency: false,
      },
    ],
    total: {
      m1: 2,
      m2: 4,
      status: true,
      difference: 2,
      isCurrency: false,
    },
  },
  {
    name: "Retired  Staff",
    data: [
      {
        m1: 2000,
        m2: 4000,
        status: true,
        difference: 2000,
        isCurrency: false,
      },
      {
        m1: 2000,
        m2: 4000,
        status: true,
        difference: 2000,
        isCurrency: false,
      },
      {
        m1: 2000,
        m2: 4000,
        status: true,
        difference: 2000,
        isCurrency: false,
      },
      {
        m1: 2000,
        m2: 4000,
        status: true,
        difference: 2000,
        isCurrency: false,
      },
      {
        m1: 2000,
        m2: 4000,
        status: true,
        difference: 2000,
        isCurrency: false,
      },
      {
        m1: 2000,
        m2: 4000,
        status: true,
        difference: 2000,
        isCurrency: false,
      },
    ],
    total: {
      m1: 2000,
      m2: 4000,
      status: true,
      difference: 2000,
      isCurrency: false,
    },
  },
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const AcademicGrades = [
  "Excellent",
  "Very Good",
  "Good",
  "Credit",
  "First Class Honours",
  "Second Class Honours, Upper",
  "Second Class Honours, Lower",
  "Third Class",
  "Pass",
  "Fail",
];
