<template>
  <form
    @submit.prevent="doFilter"
    class="flex w-full justify-between items-center"
  >
    <section :class="`flex justify-between p-1 ${width} border rounded`">
      <input
        type="text"
        v-model="filterText"
        class="input-search"
        @keyup.enter="doFilter"
        :placeholder="placeholder"
        autofocus="true"
      />

      <div class="flex justify-end gap-1.5 w-4/12">
        <div v-if="!filterText" class="p-1">
          <img
            src="@/assets/images/icons/search.svg"
            alt="icons"
            class="w-4 h-4"
          />
        </div>

        <div v-else class="text-xs flex gap-1">
          <button
            type="submit"
            class="bg-oneGreen flex items-center rounded gap-2 text-white py-1 px-3"
            @click="doFilter"
          >
            <span>Search</span>
            <spinner v-if="loading" class="w-4 h-4" color="gray-700"></spinner>
          </button>

          <button
            type="button"
            class="bg-dkgray text-white rounded py-1 px-3"
            @click="resetFilter"
          >
            <span>&#x2715;</span>
          </button>
        </div>
      </div>
    </section>
  </form>
</template>

<script>
export default {
  name: "FilterBar",

  props: {
    placeholder: {
      type: String,
      default: "Enter search",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "w-7/12",
    },
  },

  data() {
    return {
      filterText: "",
      mdas: [
        { label: "Ministry", value: "Ministry" },
        { label: "Education", value: "Education" },
        { label: "Agric", value: "Agric" },
      ],
      durations: [
        { label: "Weekly", value: "Weekly" },
        { label: "Monthly", value: "Monthly" },
        { label: "Daily", value: "Daily" },
      ],
    };
  },

  methods: {
    doFilter() {
      this.$events.fire("filter-set", this.filterText);
      this.$emit("filter-search", this.filterText);
      // this.filterText = "";
    },

    resetFilter() {
      this.filterText = "";
      this.$events.fire("filter-reset");
    },
  },
};
</script>

<style scoped>
form {
  @apply bg-white;
}
.input-search[type="text"] {
  @apply text-sm outline-none border-none focus:outline-none focus:border-none w-full;
}
.input-search::placeholder {
  @apply text-xs text-[#8D8D8D] italic font-normal font-mulish;
}
</style>
